@tailwind base;
@tailwind components;
@tailwind utilities;

.homepage {
  background-image: url('./assets/Web 1920 – 9.png');

  /* background-repeat: no-repeat !important; */
  background-attachment: fixed;
  /* background-position: center; */
}
.w-screen {
  height: 100hv;
}

.w-screen {
  height: 100hv;
}
